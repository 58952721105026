import React from 'react';
import ReactDOM from 'react-dom/client';
import PropertySearchLandingPage from './pages/PropertySearchLandingPage';
import PropertySearch from './pages/PropertySearch';
import ScrollToTop from './components/ScrollToTop';
import Profile from './pages/Profile';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQ from './pages/FAQ';
import About from './pages/About';
import PennyThoughts from './pages/PennyThoughts';
import BuyLogin from './pages/BuyLogin';
import BuySignUp from './pages/BuySignUp';
import ErrorPage from './pages/ErrorPage';
import NewPropertyList from './properties/NewPropertyList';
import EmailVerification from './pages/EmailVerification';
// import PhoneVerification from './pages/PhoneVerification';
import ForgotPassword from './pages/ForgotPassword';
import Favourites from './properties/Favourites';
import CompareProperties from './properties/CompareProperties';
import NeighbourhoodWatch from './pages/NeighbourhoodWatch';
import HowDidYouHear from './pages/HowDidYouHear';
import RepeatUser from './pages/RepeatUser';
import ARVTool from './pages/ARVTool';
import Setup from './partner/Setup';
import Dashboard from './partner/Dashboard';
import AddListing from './partner/AddListing';
import EditListing from './partner/EditListing';
import PartnerProperty from './partner/PartnerProperty';
import MarkSold from './partner/MarkSold';
import PartnerProfile from './partner/PartnerProfile';
// import WebsiteDown from './pages/WebsiteDown';
// import Development from './pages/Development';
import GenerateURL from './pages/GenerateURL';
import PreviewExpired from './pages/PreviewExpired';
import NewCheckout from './pages/NewCheckout';
import BuyBoxSetup from './onboarding/BuyBoxSetup';
import MyCities from './pages/MyCities';
import FinancialPreferences from './pages/FinancialPreferences';
import Pay from './pages/Pay';
import EditBuyBox from './pages/EditBuyBox';
import PaymentSuccess from './pages/PaymentSuccess';
import GodMode from './pages/GodMode';
import MarketExplore from './pages/MarketExplore';
import Property from './properties/Property';
import OldProperty from './properties/OldProperty';
import PickCity from './pages/PickCity';
import UpgradeCityRedirect from './pages/UpgradeCityRedirect';
import MetroPropertyList from './properties/MetroPropertyList';
import DescribeYou from './pages/DescribeYou';
import CustomPlan from './pages/CustomPlan';
import FreeCredits from './pages/FreeCredits';
import Pricing from './pages/Pricing';
import TestPropertList from './properties/TestPropertyList';
import CountyPropertyList from './properties/CountyPropertyList';
import ChooseCounties from './pages/ChooseCounties';
import NoCredits from './pages/NoCredits';
import PullRecords from './pages/PullRecords';
import PullRecordsPay from './pages/PullRecordsPay';
import PullRecordsSuccess from './pages/PullRecordsSuccess';
import JoinAffiliate from './pages/JoinAffiliate';
import ChooseProduct from './pages/ChooseProduct';
import VerifyEmail from './pages/VerifyEmail';
import Welcome from './pages/Welcome';
import DemoConfirmation from './pages/DemoConfirmation';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import allReducers from './reducers';
import './styles/App.css';
import mixpanel from 'mixpanel-browser';
import './styles/Variables.css';
import { init as initFullStory } from '@fullstory/browser';
import { PostHogProvider} from 'posthog-js/react'
import TagManager from 'react-gtm-module';
import PropertySearchRedirect from './pages/PropertySearchRedirect';
const store = configureStore({ reducer: allReducers });

// Initialize Firebase
const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE).config);
getAnalytics(app);

mixpanel.init(JSON.parse(process.env.REACT_APP_MIXPANEL).key, {debug: false});
initFullStory({ orgId: JSON.parse(process.env.REACT_APP_FULLSTORY).orgId });

const options = {
	api_host: JSON.parse(process.env.REACT_APP_POSTHOG).host
};

const tagManagerArgs = {
    gtmId: JSON.parse(process.env.REACT_APP_GOOGLE).gtmId
};
TagManager.initialize(tagManagerArgs);

const routing = (
	<Provider
	  store={store}
	>
	  	<BrowserRouter>
			<PostHogProvider 
				apiKey={JSON.parse(process.env.REACT_APP_POSTHOG).key}
				options={options}
			>
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<MyCities />} />
						<Route path="/properties-list/:id" element={<NewPropertyList />} />
						<Route path="/metro-properties-list/:id/:cityId" element={<MetroPropertyList />} />
						<Route path="/properties/:cityId/:id" element={<Property />} />
						<Route path="/old-properties/:cityId/:id" element={<OldProperty />} />
						<Route path="/quick-report" element={<PropertySearchLandingPage />} />
						<Route path="/quick-report/:id" element={<PropertySearch />} />
						<Route path="/favourites" element={<Favourites />} />
						<Route path="/compare-properties" element={<CompareProperties />} />
						<Route path="/sign-up" element={<SignUp />} />
						<Route path="/login" element={<Login />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/terms-conditions" element={<TermsAndConditions />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/about" element={<About />} />
						<Route path="/penny-for-thoughts" element={<PennyThoughts />} />
						<Route path="/buy-login" element={<BuyLogin />} />
						<Route path="/buy-sign-up" element={<BuySignUp />} />
						<Route path="/email-verification" element={<EmailVerification />} />
						{/* <Route path="/phone-verification" element={<PhoneVerification />} /> */}
						<Route path="/password-forgotten" element={<ForgotPassword />} />
						<Route path="/generate-url" element={<GenerateURL />} />
						<Route path="/link-expired" element={<PreviewExpired />} />
						<Route path="/neighborhood-watch/:id" element={<NeighbourhoodWatch />} />
						<Route path="/describe-you" element={<DescribeYou />} />
						<Route path="/how-did-you-hear" element={<HowDidYouHear />} />
						<Route path="/repeat-user" element={<RepeatUser />} />
						<Route path="/checkout" element={<NewCheckout />} />
						<Route path="/success" element={<PaymentSuccess />} />
						<Route path="/arv-tool" element={<ARVTool />} />
						<Route path="/partner/setup" element={<Setup />} />
						<Route path="/partner/dashboard" element={<Dashboard />} />
						<Route path="/partner/add-listing" element={<AddListing />} />
						<Route path="/partner/edit-listing" element={<EditListing />} />
						<Route path="/partner/:partnerId/:propertyId" element={<PartnerProperty />} />
						<Route path="/partner/:partnerId" element={<PartnerProfile />} />
						<Route path="/partner/sold" element={<MarkSold />} />
						<Route path="/buy-box-setup" element={<BuyBoxSetup />} />
						<Route path="/edit-buy-box" element={<EditBuyBox />} />
						<Route path="/my-cities" element={<MyCities />} />
						<Route path="/preferences" element={<FinancialPreferences />} />
						<Route path="/pay" element={<Pay />} />
						<Route path="/pricing" element={<Pricing />} />
						<Route path="/upgrade-city" element={<UpgradeCityRedirect />} />
						<Route path="/pick-city" element={<PickCity />} />
						<Route path="/god-mode" element={<GodMode />} />
						<Route path="/market-analysis" element={<MarketExplore />} />
						<Route path="/custom-plan" element={<CustomPlan />} />
						<Route path="/free-credits" element={<FreeCredits />} />
						<Route path="/test-properties-list/:id" element={<TestPropertList />} />
						<Route path="/county-properties-list/:id" element={<CountyPropertyList />} />
						<Route path="/choose-counties" element={<ChooseCounties />} />
						<Route path="/no-credits" element={<NoCredits />} />
						<Route path="/pull-records" element={<PullRecords />} />
						<Route path="/pull-records-pay" element={<PullRecordsPay />} />
						<Route path="/pull-records-success" element={<PullRecordsSuccess />} />
						<Route path="/choose-product" element={<ChooseProduct />} />
						<Route path="/affiliate" element={<JoinAffiliate />} />
						<Route path="/verify-email" element={<VerifyEmail />} />
						<Route path="/welcome" element={<Welcome />}/>
						<Route path="/demo-confirmation" element={<DemoConfirmation />}/>

						<Route path="/property-search" element={<PropertySearchRedirect />} />
						<Route path="/property-search/:id" element={<PropertySearchRedirect />} />
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</ScrollToTop>
			</PostHogProvider>
		</BrowserRouter>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode> {
		routing
	// } </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();