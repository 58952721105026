import React, { useState, useEffect, useRef } from 'react';
import { FilterCityInput, CustomSelect, colour } from '../styles/GlobalStyles';
import { Exit, Search } from '../assets';
import { sendCityIDErrorEmail, cloudFunctionV2, getCountySearch, formatCityNames } from '../functions';
import { LoadingStatic, UpgradeCityMap } from './';
import { counties } from '../counties';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

function ChooseCounty(props) {
    const setDisabled = props.setDisabled;
    const setChosenMarket = props.setChosenMarket;
    const chosenMarket = props.chosenMarket;
    const geographyType = props.geographyType;
    const setGeographyType = props.setGeographyType;
    const customCities = props.customCities;
    const setCustomCities = props.setCustomCities;
    const customZipCodes = props.customZipCodes;
    const setCustomZipCodes = props.setCustomZipCodes;
    const allZipCodes = props.allZipCodes;
    const setAllZipCodes = props.setAllZipCodes;
    const zipOptions = props.zipOptions;
    const setZipOptions = props.setZipOptions;
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [city, setCity] = useState("");
    const [options, setOptions] = useState([]);
    const [chosenCity, setChosenCity] = useState("");
    const [loading, setLoading] = useState(false);
    const [cityBoundaries, setCityBoundaries] = useState([]);
    const [countyAreas, setCountyAreas] = useState([]);
    const [centre, setCentre] = useState({ lat: 39.841502, lng: -101.309177 });
    const [zoom, setZoom] = useState(4);
    const [cityError, setCityError] = useState(false);
    const [mapLoading, setMapLoading] = useState(false);
    const [customCitiesOpen, setCustomCitiesOpen] = useState(false);
    const [zipText, setZipText] = useState("");
    const mapRef = useRef(null);
    const mapRefContainer = useRef(null);

    const geographies = [
        "Entire County",
        "City",
        "ZIP Codes"
    ];

    useEffect(() => {
        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};

		return () => {
		  window.addEventListener('resize', handleWindowResize);
		};
    }, [innerWidth]);

    const changeCity = async(val) => {
        setCity(val);

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
                    newArray.push(counties[index]);
                }
            }
        };
        setOptions(newArray);
    };

    const onZoomChanged = () => {
        if ( mapRef.current !== null ) {
            setZoom(mapRef.current.getZoom());
        }
    };

    const resetCity = () => {
        changeCity("");
    };

    const scrollToMap = () => {
        if ( mapRefContainer !== null && innerWidth < 1000 && mapRefContainer.current !== null) {
            mapRefContainer.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    const selectCity = async(item) => {
        setLoading(true);
        setCountyAreas([]);
        setCity("");
        setMapLoading(true);

        const boundariesURL = process.env.REACT_APP_BOUNDARIES_URL;
        const checkedState = item.state === "D.C." ? "DC" : item.state;
        const docCityName = "District of Columbia";

        const params = {
            city: item.state === "D.C." ? docCityName : item.county,
            state: checkedState,
            county: true,
            returnCityId: "True"
        };
        const fullCountyName = await formatCityNames(item);
        setChosenCity(fullCountyName);
        const query = await cloudFunctionV2(boundariesURL, params);
        if ( query.status === 200 ) {
            setCentre(query.centre);
            setMapLoading(false);
            setCityBoundaries(query.data);
            if ( query.data.length > 0 ) {
                setZoom(8);
            }
        }

        const countyParams = {
            countyId: `CTY${item.code}`
        }
		const getCountyParams = await getCountySearch(countyParams);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "county",
			queryString: getCountyParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            if ( body === "<countyId> does not exist. Enter valid <countyId>." ) {
                setDisabled(true);
                setCityError(true);

                const cityIdErrorPage = "Upgrade City Page";
                const apiResponse = `Error with citySearchLive query`;
                await sendCityIDErrorEmail(fullCountyName, params.state, cityIdErrorPage, apiResponse);
            }
            else {
                body.price = item.price;
                setCityError(false);

                const newUniqueCityList = [];
                const msaCityList = [];
                const msaCityIds = [];
                for (let index = 0; index < body.msaCityList.length; index++) {
                    const element = body.msaCityList[index];
                    const newMSAObject = {
                        city: element.city,
                        state: element.state,
                        cityId: element.cityId
                    };
                    msaCityIds.push(element.cityId);
                    msaCityList.push(newMSAObject);

                    const cityIndex = newUniqueCityList.findIndex(e => e.city === element.city && e.state === element.state);
                    if ( cityIndex === -1 ) {
                        newUniqueCityList.push(element);
                    }
                };
                newUniqueCityList.sort((a, b) => a.city.localeCompare(b.city));
                body.msaCityIds = msaCityIds;
                body.metroCities = msaCityList.sort((a, b) => a.city.localeCompare(b.city));
                body.state = item.state;
                body.metroArea = true;
                setDisabled(false);

                const newAllZipCodes = [];
                for (let index = 0; index < body.msaCityList.length; index++) {
                    const element = body.msaCityList[index];
                    const newObject = {
                        city: element.city,
                        state: element.state,
                        cityId: element.cityId,
                        zipCodes: element.activeZipCodes
                    };
                    newAllZipCodes.push(newObject);
                };
                setAllZipCodes(newAllZipCodes);

                const newObject = {
                    msaTitle: `${body.countyName}, ${body.state}`,
                    msaCode: body.countyId,
                    msaCityIds: body.msaCityIds,
                    metroCities: body.metroCities,
                    metroArea: body.metroArea
                }
                setChosenMarket(newObject);
            }
        }
        else {
            setDisabled(true);
            setCityError(true);

            const cityIdErrorPage = "Upgrade City Page";
            const apiResponse = `Error with citySearchLive query`;
            await sendCityIDErrorEmail(fullCountyName, params.state, cityIdErrorPage, apiResponse);
        }
        setLoading(false);
        scrollToMap();
    };

    const changeGeography = (val) => {
        setGeographyType(val);
    };

    const cityOptionsSelected = (val) => {
        const index = customCities.indexOf(val);
		if ( index === -1 ) {
			return false;
		}
		else {
			return true;
		}
    };

    const changeCustomCities = (vals) => {
        const newArray = [...customCities];
		const index = newArray.indexOf(vals.cityId);
		if ( index === -1 ) {
			newArray.push(vals.cityId);
		}
		else {
			newArray.splice(index, 1);
		}
		setCustomCities(newArray);
    };

    const changeZipText = (val) => {
        setZipText(val);

        const newZipOptions = [];
        for (let index = 0; index < allZipCodes.length; index++) {
            const element = allZipCodes[index];
            const zipCodes = element.zipCodes;
            for (let ii = 0; ii < zipCodes.length; ii++) {
                const zipCode = zipCodes[ii];
                const zipOptionIndex = newZipOptions.findIndex(e => e.value === zipCode);
                if ( zipCode.includes(val) && zipOptionIndex === -1 && (customZipCodes.includes(zipCode) === false) ) {
                    const newObject = {
                        label: `${zipCode} - ${element.city}, ${element.state}`,
                        value: zipCode
                    };
                    newZipOptions.push(newObject);
                }
            }
        };

        setZipOptions(newZipOptions);
    };

    const resetZipText = () => {
        setZipText("");
    };

    const removeZipCode = (val) => {
        const newArray = customZipCodes.filter(e => e !== val);
        setCustomZipCodes(newArray);
    };

    const selectZipCode = (item) => {
        const newArray = [...customZipCodes];
        newArray.push(item.value);
        setCustomZipCodes(newArray);
        setZipText("");
        setZipOptions([]);
    };

    const styles = {
        menuItem: {
            backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
        }
    };

    return (
        <div className="upgrade-city-body-container relative-container">
            <div className="pick-plan-county-left-container">
                <div className="pick-plan-input-container">
                    <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                        <FilterCityInput
                            value={city}
                            type="text"
                            id="upgrade-city-input"
                            placeholder="Search for any county in the U.S..."
                            onChange={(text) => changeCity(text.target.value)}
                            disabled={false}
                        />
                        <img
                            src={city === "" ? Search : Exit}
                            className={city === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                            alt="Search"
                            onClick={() => city === "" ? null : resetCity()}
                        />
                        {
                            options.length > 0 && city !== "" ?
                                <div className="city-filtering-outer-container">
                                    {
                                        options.map((item, index) =>
                                            <div 
                                                className="city-filtering-element"
                                                key={index}
                                                onClick={() => selectCity(item)}
                                            >
                                                <span className="body-regular colour-primary">
                                                    {formatCityNames(item)}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            :
                            null
                        }
                    </div>
                    <div className="upgrade-city-input-text-container">
                        {
                            chosenCity === "" ?
                            null
                            :
                            <div className="upgrade-city-details-container margin-medium">
                                <h3 className="heading-small-semibold colour-primary margin-large">
                                    {chosenCity}
                                </h3>
                                {
                                    loading === true ?
                                    <div className="upgrade-city-loading-container">
                                        <LoadingStatic />
                                        <span className="body-semibold colour-primary block-text margin-top-small margin-x-small">
                                            Loading...
                                        </span>
                                        <span className="body-regular colour-secondary">
                                            Getting geography information
                                        </span>
                                    </div>
                                    :
                                    cityError === true ?
                                    <div className="upgrade-city-response-container">
                                        <span className="body-regular colour-error">
                                            We're afraid there was an error getting data for this county,
                                            please try another metro or contact us for more details
                                        </span>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                    {
                        chosenMarket === null ?
                        null
                        :
                        <div className="choose-county-options-selector-container margin-medium">
                            <Box>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="buy-box-geography-select-label"
                                        id={`buy-box-geography-select`}
                                        value={geographyType}
                                        label=""
                                        onChange={(event) => changeGeography(event.target.value)}
                                        input={<CustomSelect />}
                                        disabled={loading}
                                    >
                                        {
                                            geographies.map((item, index) =>
                                                <MenuItem
                                                    key={index}
                                                    value={index}
                                                    style={styles.menuItem}
                                                    disabled={false}
                                                >
                                                    {item}
                                                </MenuItem>	
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    }
                </div>
            </div>
            {
                geographyType === 0 ?
                <div className="pick-plan-map-container">
                    <UpgradeCityMap
                        cityBoundaries={cityBoundaries}
                        centre={centre}
                        zoom={zoom}
                        mapRef={mapRef}
                        onZoomChanged={onZoomChanged}
                        metroAreas={countyAreas}
                        upgradedMSA={true}
                        mapLoading={mapLoading}
                        mapRefContainer={mapRefContainer}
                    />
                </div>
                :
                geographyType === 1 ?
                <div className="choose-county-city-selector-container">
                    <span className="body-semibold colour-primary block-text margin-x-small">
                        Which cities would you like to focus on?
                    </span>
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                labelId="buy-box-city-select-label"
                                id={`buy-box-city-select`}
                                value={customCities}
								renderValue={(selected) => `Cities (${selected.length})`}
								input={<CustomSelect />}
								multiple
								open={customCitiesOpen}
								onOpen={() => setCustomCitiesOpen(true)}
								onClose={() => setCustomCitiesOpen(false)}
                            >
                                {
                                    chosenMarket.metroCities.map((item, index) =>
                                        <MenuItem
                                            key={index}
                                            value={item.cityId}
                                            style={styles.menuItem}
                                            disabled={false}
                                            onClick={() => changeCustomCities(item)}
                                        >
                                            <div className="sign-up-component-how-did-you-hear-row">
												<div className="checkbox-container">
													{
														cityOptionsSelected(item.cityId) === true ?
														<div className="checkbox-icon">
														</div>
														:
														null
													}
												</div>
												<span className="body-regular colour-primary">
													{item.city}, {item.state}
												</span>
											</div>
                                        </MenuItem>	
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                :
                geographyType === 2 ?
                <div className="choose-county-city-selector-container">
                    <span className="body-semibold colour-primary block-text margin-x-small">
                        Which ZIP codes would you like to focus on?
                    </span>
                    {
                        customZipCodes.length > 0 ?
                        <div className="choose-county-zip-codes-container margin-x-small">
                            {
                                customZipCodes.map((item, index) =>
                                    <div 
                                        className="choose-county-custom-zip-code-bubble"
                                        key={index}
                                    >
                                        <span 
                                            key={index}
                                            className="body-regular colour-primary"
                                        >
                                            {item}
                                        </span>
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="23" 
                                            height="24" 
                                            viewBox="0 0 23 24" 
                                            fill="none"
                                            className="choose-county-zip-options-icon cursor-pointer"
                                            onClick={() => removeZipCode(item)}
                                        >
                                            <path 
                                                fillRule="evenodd" 
                                                clipRule="evenodd" 
                                                d="M2.75829 0.419021C2.2903 -0.100741 1.48956 -0.142706 0.969802 0.32529C0.45004 0.793286 0.408076 1.59402 0.876071 2.11378L9.7968 12.0213L0.914356 21.8862C0.446361 22.406 0.488325 23.2067 1.00809 23.6747C1.52785 24.1427 2.32858 24.1007 2.79658 23.581L11.5009 13.9138L20.2052 23.5809C20.6732 24.1007 21.4739 24.1427 21.9937 23.6747C22.5134 23.2067 22.5554 22.4059 22.0874 21.8862L13.205 12.0213L22.1257 2.11382C22.5937 1.59406 22.5517 0.793322 22.032 0.325326C21.5122 -0.142669 20.7115 -0.100704 20.2435 0.419057L11.5009 10.1287L2.75829 0.419021Z" 
                                                fill="#2F4858"
                                            />
                                        </svg>
                                    </div>
                                )
                            }
                        </div>
                        :
                        null
                    }
                    <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                        <FilterCityInput
                            value={zipText}
                            type="text"
                            id="select-zip-text-input"
                            placeholder="Search for ZIP codes.."
                            onChange={(text) => changeZipText(text.target.value)}
                            disabled={false}
                        />
                        <img
                            src={zipText === "" ? Search : Exit}
                            className={zipText === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                            alt="Search"
                            onClick={() => zipText === "" ? null : resetZipText()}
                        />
                        {
                            zipOptions.length > 0 && zipText !== "" ?
                                <div className="city-filtering-outer-container">
                                    {
                                        zipOptions.map((item, index) =>
                                            <div 
                                                className="city-filtering-element"
                                                key={index}
                                                onClick={() => selectZipCode(item)}
                                            >
                                                <span className="body-regular colour-primary">
                                                    {item.label}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                null
            }
        </div>
    )
};

export default ChooseCounty;